<template>
  <Logs :id="id" log-type="Lesson" />
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
// Components
import Logs from "@/components/templates/Logs";

export default {
  components: {
    Logs
  },
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    lesson: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();

    // Composables
    const { getText } = useTexts();

    // COMPUTED
    const documentTitle = computed(() => {
      const title = `${t("app.logs")} - ${t("app.lessons", 2)} - ${t(
        "app.teaching"
      )}`;

      const name = getText(props.lesson?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    return {
      documentTitle
    };
  }
};
</script>
